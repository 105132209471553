import Head from "next/head";
import { Error } from "../components/Error";

export default function Custom404() {
  return (
    <>
      <Head>
        <title>Error 404 (Not Found)</title>
      </Head>
      <Error errorCode={404} />;
    </>
  );
}
